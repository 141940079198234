import React, { useState, useEffect } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import axios from 'axios';
import _ from 'lodash';
import { Button, LinearProgress } from '@material-ui/core';
import styled from '@emotion/styled';

import { COLOR } from '../../constants';
import { getTheme } from '../../utility/theme';
import Layout from '../Layout';
import { CsvRow } from './RowViva';
import { Container, CounterContainer, Inner } from './PageCsv';
import SEO from '../seo';

const muiTheme = (userTheme: string) =>
  createMuiTheme({
    palette: {
      type: userTheme === 'dark' ? 'dark' : 'light',
      primary: {
        main: COLOR.PRIMARY,
      },
      text: {
        primary: userTheme === 'dark' ? '#ffffff' : '#333333',
      },
    },
  });

type TagProps = {
  selected: boolean;
}
const Pill = styled.div<TagProps>`
  cursor: pointer;
  font-size: 0.8rem;
  font-style: italic;
  background-color: ${props => props.selected ? '#ff6961' : '#777'};
  padding: 2px 6px;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 4px;
`;
const Pills = styled.span`
  display: inline-flex;
  padding: 0;
  flex-wrap: wrap;
  border-top: 1px solid white;
  padding-top: 1rem;
`;


const processRow = (row) => {
  const question = row[0];
  const answer = row[1];
  const passRate = row[2];
  const type = row[3];
  const tagColumns = row.slice(4);
  const allTags = tagColumns.map(col => {
    const arr = col.split(', ');
    return arr;
  });
  const tags = _.compact(_.uniq(_.flatten(allTags)));
  return {
    question,
    answer,
    passRate,
    type,
    tags,
  }
};

const getTags = rows => {
  const data = rows.slice(1);
  const tags = data.map(d => {
    return d.system;
  })
  // console.log('tags:', tags);
}

const processRows = (raw) => {
  const header = raw[0];

  const rows = raw.slice(1);
  const systems = new Set();
  const tags = new Set();

  const processedRows = rows.map((row, j) => {
    const processedCells = row.reduce((acc, cell, i) => {
      if (header[i] === 'system') {
        systems.add(cell);
      }
      if (header[i] === 'tags') {
        const cellTags = cell.split(',')
        cellTags.forEach(t => tags.add(t));
      }

      const data = {
        ...acc,
        [header[i]]: cell,
      }
      return data;
    }, {})

    return ({
      data: processedCells,
      dataRowNumber: j + 1,
      countViewed: row[10] || 0,
    })
  })
  return ({
    rows: processedRows,
    systems: Array.from(systems).filter(e => e),
    tags: Array.from(tags).filter(e => e),
  })
}
const Spacer = styled.div`
  margin-bottom: 2rem;
`;

const SPREADSHEET = '12bVHSZT2Qc5Lslf5RXL2xealjZTHN3oQ1r4IeEsku5s';
const SAQ_SHEETS = [
  '2021.1',
  '2020.2',
];
const RANGES = SAQ_SHEETS.map(s => 'ranges=' + s + '!A2:I').join('&');
const KEY = process.env.GATSBY_GOOGLE_SHEETS_KEY;

function handleKeyPress() {
  useEffect(() => {
  }, []);
}
const Buttons = styled.div`
  button {
    margin-right: 1rem;
  }
`;

const DATA_EMPTY = {
  Question: '',
  Opening: '',
  Start: '',
  Issues: '',
  Pass: '',
}
const ROW_EMPTY = {
  data: DATA_EMPTY,
  dataRowNumber: 0,
  countViewed: 0,
}

const PillHeading = styled.span`
  margin-right: 1rem;
`

const getRows = (initialRows, currentTag, currentSystem, pill, type) => {
  if (pill === 'All' && type === 'tag') {
    if (currentSystem === 'All') {
      // Systems: All, Tags: All
      return initialRows;
    }
    // Systems: XX, Tags: All
    const filtered = initialRows.filter(row => row.data.system === currentSystem);

    return filtered;
  }
  if (pill === 'All' && type === 'system') {
    if (currentTag === 'All') {
      // Systems: All, Tags: All
      return initialRows;
    }
    // Systems: All, Tags: currentTag
    const filtered = initialRows.filter(row => row.data.tags && row.data.tags.includes(currentTag));

    return filtered;
  }
  if (type === 'tag') {
    // Systems: All, Tags: XX
    if (currentSystem === 'All') {
      const filtered = initialRows.filter(row => row.data.tags && row.data.tags.includes(pill));
      return filtered;
    }
    // Systems: currentSysytem, Tags: XX
    const filtered = initialRows.filter(row => row.data.tags && row.data.tags.includes(pill) && row.data.system === currentSystem)

    return filtered;
  }

  if (type === 'system') {
    // Systems: XX, Tags: All
    if (currentTag === 'All') {
      const filtered = initialRows.filter(row => row.data.system === pill);
      return filtered;
    }
    // Systems: XX, Tags: currentTag
    const filtered = initialRows.filter(row => row.data.system === pill && row.data.tags && row.data.tags.includes(currentTag));

    return filtered;
  }

  // we should never get here
  console.log('OOPS');
  return initialRows;

}

const getSortedByNew = (rows) => {
  const shuffle = () => Math.random() - 0.5;
  const sorted = _.sortBy(rows, function (x) {
    return Number(x.countViewed);
  }, shuffle);
  return sorted;
}

const Page = (props) => {
  const userTheme = getTheme();
  const theme = muiTheme(userTheme);

  const { range, sheetId, spreadsheetId, title } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [initialRows, setInitialRows] = useState([]);
  const [displayRows, setDisplayRows] = useState([ROW_EMPTY]);
  const [clicks, setClicks] = useState(0);

  const [allSystems, setAllSystems] = useState([]);
  const [currentSystem, setCurrentSystem] = useState('All');

  const [allTags, setAllTags] = useState([]);
  const [currentTag, setCurrentTag] = useState('All');

  const incrementClicks = () => {
    setClicks(clicks + 1);
  }
  const decrementClicks = () => {
    setClicks(clicks - 1);
  }

  const incrementGoogleCounter = async (dataRowNumber) => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    const sheetRowNumber = dataRowNumber + 1;

    const URL = `https://medicalgems.com/increment?spreadsheetId=${spreadsheetId}&sheet=${sheetId}&row=${sheetRowNumber}`;
    // won't be able to test this locally because we have medicalgems.com and localhost

    const result = await axios.get(URL, {
      headers: {
        // 'Access-Control-Allow-Origin' : '*',
        // crossdomain: true,
        // 'Access-Control-Allow-Credentials':true,
        // mode: 'no-cors',
      }
    });
    // console.log('result:', result);
  }

  const sortByNew = () => {
    const sorted = getSortedByNew(initialRows);
    setDisplayRows(sorted);
    setClicks(0);
  }
  const sortByNumber = () => {
    setDisplayRows(initialRows);
    setClicks(0);
  }

  useEffect(() => {
    async function getData() {
      const KEY = process.env.GATSBY_GOOGLE_SHEETS_KEY;
      const URL = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${KEY}`;
      const result = await axios.get(URL).catch(console.log);
      const resultValues = result && result.data.values;

      const processed = processRows(resultValues);
      setInitialRows(processed.rows);
      const sorted = getSortedByNew(processed.rows);
      setDisplayRows(sorted);
      setClicks(0);

      const systems = processed.systems;
      setAllSystems(systems);
      setAllTags(processed.tags);
      setIsLoading(false);
    }
    getData();

    function onKeyPress(e) {
      if (e.key === 'e') {

      }
    }
    window.addEventListener('keydown', onKeyPress);
    return () => window.removeEventListener('keydown', onKeyPress);
  },[]);

  const shuffle = () => {
    const shuffled = _.sortBy(displayRows, () => Math.random() - 0.5);
    setDisplayRows(shuffled);
    setClicks(0);
  };

  const handleSystemClick = (str) => () => {
    setCurrentSystem(str);
    const rows = getRows(initialRows, currentTag, currentSystem, str, 'system');
    setDisplayRows(rows);
  };
  const handleAllSystemsClick = () => {
    setCurrentSystem('All');
    const rows = getRows(initialRows, currentTag, currentSystem, 'All', 'system');
    setDisplayRows(rows);
  };

  const handleTagClick = (str) => () => {
    setCurrentTag(str);
    const rows = getRows(initialRows, currentTag, currentSystem, str, 'tag');
    setDisplayRows(rows);
  };
  const handleAllTagsClick = () => {
    setCurrentTag('All');
    const rows = getRows(initialRows, currentTag, currentSystem, 'All', 'tag');
    setDisplayRows(rows);
  };

  const counter = displayRows ? `${clicks} / ${displayRows.length}` : '...';



  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title={title} />
        <h1>{title}</h1>
        <Container>
          <Inner>
          <CounterContainer>
            {counter}
          </CounterContainer>
          </Inner>
        </Container>

        <Spacer />

        <Buttons>
          <Button type="button" color="primary" variant="contained" onClick={shuffle}>
              Shuffle
          </Button>
          <Button type="button" color="primary" variant="contained" onClick={sortByNew}>
              New
          </Button>
          <Button type="button" color="primary" variant="contained" onClick={sortByNumber}>
              123
          </Button>
        </Buttons>

        <Spacer />

        {isLoading && <LinearProgress />}

        {!isLoading && displayRows.map((r, i) => {
          const key = i + r.data.Question.slice(0, 50);
          return (
            <CsvRow
              key={key}
              row={r}
              clicks={clicks}
              range={range}

              incrementClicks={incrementClicks}
              decrementClicks={decrementClicks}
              incrementGoogleCounter={incrementGoogleCounter}
            />
           )
        })}

      </Layout>
    </ThemeProvider>
  )
}

export default Page;
