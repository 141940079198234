import React from 'react';
import PageCsvViva from '../components/Csv/PageCsvViva';

const SPREADSHEET = '1pEDJ6N7M1Ucq0T6fQnP2aVdQfa1sneOh0tx-1Ds85K8';
const SHEET_ID = '2021.2';
const TITLE = `VIVA ${SHEET_ID}`
const RANGE = 'A1:O';

export default function Page () {
  const RANGE_COMPLETE = SHEET_ID ? `${SHEET_ID}!${RANGE}` : RANGE;
  return (
    <PageCsvViva
      title={TITLE}
      spreadsheetId={SPREADSHEET}
      sheetId={SHEET_ID}
      range={RANGE_COMPLETE}
    />
  )

}
