import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  COLOR, MAX_TABLET_WIDTH,
} from '../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  /* min-height: 5rem; */
  cursor: pointer;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  padding: 1rem;
  border: 1px solid white;
  width: calc(100% - 1rem);
  white-space: pre-wrap;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    width: 100%;
  }

`;

const Top = styled.div`
  padding: 1rem;
  /* padding-bottom: 2.5rem; */
  border: 1px solid white;
  width: calc(100% - 1rem);
  white-space: pre-wrap;
  position: relative;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    width: 100%;
  }

  position: relative;
`;

type BottomProps = {
  show: boolean;
}
const Bottom = styled.div<BottomProps>`
  padding: 1rem;
  border: 1px solid white;
  width: calc(100% - 1rem);
  white-space: pre-wrap;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    width: 100%;
  }

  display: ${props => props.show ? 'visible' : 'none' };
  background-color: ${props => props.show ? '#303030' : '#121212'};
  position: relative;
  padding-bottom: 2rem;
`;


const Tag = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  background-color: #777;
  padding: 2px 6px;
  margin-right: 0.5rem;
  border-radius: 4px;
`;
const Tags = styled.div`
  display: inline-flex;
  margin-left: 0.5rem;
`;

const Count = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  border: 1px solid white;
  padding: 2px 6px;
  border-radius: 4px;
`;

const getShow = (clicks, show) => {
  if (clicks === 0) {
    return false;
  }
  return show;
}

type AbbrevProps = {
  cell?: boolean;
  color?: string;
}
export const Abbrev = styled.span<AbbrevProps>`
  font-size: ${p => {
    return p.cell ? '0.7rem' : `0.9rem`;
  }};
  border: 1px solid ${p => p.color};
  border-radius: 3px;
  padding: 2px 6px;
  margin-left: 0.5rem;
  margin-right: ${p => {
    return p.cell ? 0 : `0.5rem`;
  }};
  margin-bottom: ${p => {
    return p.cell ? 0 : `1rem`;
  }};
`;
const Highlight = styled.div`
  padding: 1rem;
  margin: 1rem -1rem 0;
  /* background: #9acced; */
  background: #333;
`;
const Important = styled.div`
  padding: 1rem;
  margin: 1rem -1rem 0;
  /* background: #9acced; */
  background: #ffc26c;
  color: black;
`;
const Issues = styled.div`
  margin-top: 1rem;
`;

type CsvRowProps = {
  row: { data: any, dataRowNumber: number, countViewed: number };
  clicks: number;
  range: string;

  incrementClicks: () => void;
  decrementClicks: () => void;
  incrementGoogleCounter: (dataRowNumber: number) => void;
}

const hasTags = (str) => {
  if (!str || !str.length) {
    return false;
  }
  return true
}

const getTags = (arrStr) => {
  const result = arrStr.split(',');
  return result;
}
export function CsvRow (props: CsvRowProps) {
  const {
    row,
    clicks,
    range,

    incrementClicks,
    decrementClicks,
    incrementGoogleCounter,
  } = props;
  const [show, setShow] = useState(false);

  const display = getShow(clicks, show);

  const handleClick = (e) => {
    if (!show) {
      const dataRowNumber = row.dataRowNumber;
      if (process.env.NODE_ENV !== 'development') {
        incrementGoogleCounter(dataRowNumber);
      }
    }

    setShow(!show);
    if (display) {
      decrementClicks();
    } else {
      incrementClicks();
    }
  }
  return (
    <Container onClick={handleClick}>
      <Top>
        {row.data.Question}

        <Count>
          {row.countViewed}
        </Count>

        <Highlight>
          {row.data.Opening}
        </Highlight>

      </Top>

      <Bottom show={display}>
        {row.data.Start}

        <Highlight>
          {row.data.Issues}
        </Highlight>

        {row.data.Important && (<Important>
          {row.data.Important}
        </Important>)}
      </Bottom>

    </Container>
  )
}
